import React from "react";
import Heading from "../../components/towncrier/ui/heading";
import CreateJobForm from "../../components/towncrier/create-job-form";

export default function CreateJob() {
    return (
        <main className={'create-job towncrier-content'}>
            <div className='section'>
                <Heading title={'Create a Job Listing'}/>
                <CreateJobForm/>
            </div>
        </main>
    );
}