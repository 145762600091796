import React from 'react'
import styles from './icon-text.module.css'

export default function IconText({ text, icon, style }) {
    return (
        <div className={styles.iconContentItem + ' ' +  style}>
            <div>{icon}</div>
            <p>{text}</p>
        </div>
    );
}