import React from 'react'
import styles from './icon-text.module.css'
import {Link} from "react-router-dom";

export default function IconLink({ link, icon }) {
    return (
        <div className={styles.iconContentItem}>
            <div>{icon}</div>
            <Link to={link}>{link}</Link>
        </div>
    );
}