import React, {useState} from "react";
import Heading from "../../components/towncrier/ui/heading";
import {Navigate, useLocation} from "react-router-dom";
import EditBusinessForm from "../../components/towncrier/edit-business-form";

export default function EditBusiness() {
    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    let location = useLocation();

    if (user.role !== 1) {
        return <Navigate to={'/'}/>
    }
    return (
        <main className={'edit-business towncrier-content'}>
            <div className='section'>
                <Heading title={`Edit ${location.state.businessName}`}/>
                <EditBusinessForm business={location.state}/>
            </div>
        </main>
    );
}