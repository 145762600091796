import React, {useEffect, useMemo, useState} from 'react';
import styles from "./all-events.module.css";
import style from "./events-offers.module.css";
import HeadingTwo from "./ui/heading-two";
import api from "../../api";
import Pagination from "../pagination";
import StatusJobCard from "./ui/status-job-card";

let PageSize = 6;

export default function DeniedJobs({user}) {

    const [currentPage, setCurrentPage] = useState(1);
    const [jobs, setJobs] = useState([]);
    const [businessName, setBusinessName] = useState('');

    const url = api;

    const getBusinessInfo = async () => {
        try {
            const response = await fetch(url + 'app/businessSelection/' + user.businessId);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setBusinessName(data.business.businessName);

        } catch (error) {
            console.error('Error fetching weather data:', error);
        }
    };

    const getDeniedJobs = async () => {
        try {
            const response = await fetch(url + 'app/allDeniedJobs/');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) {
                setJobs(data.jobs);
            }
        } catch (error) {
            console.error('Error fetching jobs data:', error);
        }
    }

    useEffect(() => {
        getBusinessInfo();
        getDeniedJobs();
    }, [businessName]);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return jobs.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, jobs]);

    const items = currentTableData.map((job) => {
        return <StatusJobCard userInfo={user} job={job}/>;
    });

    return (
        <div className={'bg-light-green mb-10 ' + style.cardOffers}>
            <HeadingTwo title={'Denied Jobs'} className={''}/>
            {
                !items.length ?
                    <p>No denied jobs at this time</p>
                    :
                    <div className={styles.grid}>{items}</div>
            }
            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={jobs.length}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </div>
    );
}