import React, {useState} from "react";
import Heading from "../../components/towncrier/ui/heading";
import {Navigate} from "react-router-dom";
import PendingJobs from "../../components/towncrier/pending-jobs";
import DeniedJobs from "../../components/towncrier/denied-jobs";

export default function JobsManagement() {
    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    if (user.role !== 1) {
        return <Navigate to={'/'}/>
    }
    return (
        <main className={'jobs-management towncrier-content'}>
            <div className='section'>
                <Heading title={'Job Listings Management'} />
                <PendingJobs user={user} />
                <DeniedJobs  user={user} />
            </div>
        </main>
    );
}