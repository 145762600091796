import React, {useState} from 'react'
import styles from "./card-event.module.css";
import JoinEventModal from "../join-event-modal";
import DetailsEventModal from "../details-event-modal";

export default function CardEvent({event, user}) {
    const [show, setShow] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    function months(month) {
        switch (month) {
            case '01':
                return 'January';
            case '02':
                return 'February';
            case '03':
                return 'March';
            case '04':
                return 'April';
            case '05':
                return 'May';
            case '06':
                return 'June';
            case '07':
                return 'July';
            case '08':
                return 'August';
            case '09':
                return 'September';
            case '10':
                return 'October';
            case '11':
                return 'November';
            case '12':
                return 'December';
            default:
                return '';
        }
    }

    const startingDate = event.startingDate.split('T');
    const endingDate = event.endingDate.split('T');

    const startingDay = startingDate[0].split('-');
    const endingDay = endingDate[0].split('-');
    const startingHours = startingDate[1].split(':');
    const endingHours = endingDate[1].split(':');

    const startingMonth = months(startingDay[1]);
    const startingYear = startingDay[0];
    const endingMonth = months(endingDay[1]);
    const endingYear = endingDay[0];
    const startingTime = startingHours[0] + ':' + startingHours[1];
    const endingTime = endingHours[0] + ':' + endingHours[1];

    let day = '';
    let month = '';
    let year = '';
    let time = '';

    let startingDateEvent = startingMonth.substring(0, 3) + '. ' + startingDay[2] + ', ' + startingYear;
    let endingDateEvent = endingMonth.substring(0, 3) + '. ' + endingDay[2] + ', ' + endingYear;

    if (startingYear !== endingYear) {
        year = startingYear + ' - ' + endingYear;
    } else {
        year = endingYear;
    }

    if (startingMonth !== endingMonth) {
        month = startingMonth.substring(0, 3) + '. - ' + endingMonth.substring(0, 3) + '.';
    } else {
        month = endingMonth;
    }

    if (startingDay[2] !== endingDay[2]) {
        day = startingDay[2] + ' - ' + endingDay[2];
    } else if (startingDay[2] === endingDay[2]) {
        day = endingDay[2];
    }

    if (startingTime !== endingTime) {
        time = 'From ' + startingTime + ' to ' + endingTime;
    } else {
        time = endingTime;
    }

    const showModal = () => {
        setShow(true);
    };
    const showModalDetails = () => {
        setShowDetails(true);
    };

    const hideModal = () => {
        setShow(false);
    };
    const hideModalDetails = () => {
        setShowDetails(false);
    };

    return (
        <div className={styles.col} key={event.eventsId}>
            <div className={styles.content}>
                <div className={styles.date}>
                    <p className={styles.month}>{month}</p>
                    <p className={styles.day}>{day}</p>
                    <p className={styles.month}>{year}</p>
                </div>
                <div className={styles.info}>
                    <p className={styles.title}>{event.title}</p>
                    <p className={styles.location}>{event.address}</p>
                    <p className={styles.location}>{time}</p>
                </div>
            </div>
            <div className={styles.footer}>
                <p>{!event.shortDesc ? `${event.description.substring(0, 100)}...` : event.shortDesc}</p>
                <div className={styles.buttons}>
                    <span><button onClick={e => {
                        showModalDetails();
                    }}>View More</button></span>
                    <span><button onClick={e => {
                        showModal();
                    }}>Join</button></span>
                </div>
            </div>
            <JoinEventModal show={show} handleClose={e => {
                hideModal();
            }} title={event.title} eventId={event.eventsId}
                            date={(startingDateEvent === endingDateEvent) ? `${startingDateEvent} ${time}` : `${startingDateEvent} - ${endingDateEvent} ${time}`}
            />
            <DetailsEventModal showDetails={showDetails} handleCloseDetails={e => {
                hideModalDetails();
            }} title={event.title} description={event.description} address={event.address} organiser={event.organiser}
                               eventId={event.eventsId} status={event.status} user={user}
                               date={(startingDateEvent === endingDateEvent) ? `${startingDateEvent} ${time}` : `${startingDateEvent} - ${endingDateEvent} ${time}`}
            />
        </div>
    );
}