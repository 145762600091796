import React, {useState} from 'react'
import {useNavigate} from "react-router-dom";
import styles from './heading.module.css'
import {FiLogOut} from "react-icons/fi";
import HeadingOne from "./heading-one";

export default function Heading({ title }) {
    const navigate = useNavigate();
    const [authTokens, setAuthTokens] = useState(
        localStorage.getItem("tokens") || ""
    );
    const handleLogout = () => {
        localStorage.clear();
        setAuthTokens("");
        navigate('/login')
        navigate(0)
    };

    return (
        <div className={styles.heading}>
            <HeadingOne title={title}/>
            <div className={styles.link}><button onClick={handleLogout}>Log out <FiLogOut size={20} /></button></div>
        </div>
    );
}