import React from "react";
import Heading from "../../components/towncrier/ui/heading";
import CreateEventForm from "../../components/towncrier/create-event-form";

export default function CreateEvent() {
    return (
        <main className={'create-event towncrier-content'}>
            <div className='section'>
                <Heading title={'Create an Event or Workshop'}/>
                <CreateEventForm/>
            </div>
        </main>
    );
}