import React from "react";
import Heading from "../../components/towncrier/ui/heading";
import CreateMemberForm from "../../components/towncrier/create-member-form";

export default function CreateMember() {
    return (
        <main className={'create-member towncrier-content'}>
            <div className='section'>
                <Heading title={'Create a New Member'}/>
                <CreateMemberForm/>
            </div>
        </main>
    );
}