import React, {useEffect, useMemo, useState} from 'react';
import styles from "./all-events.module.css";
import style from "./events-offers.module.css";
import HeadingTwo from "./ui/heading-two";
import api from "../../api";
import Button from "./ui/button";
import Pagination from "../pagination";
import UserEventCard from "./ui/user-event-card";

let PageSize = 6;

export default function ManageEvents() {

    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    const [currentPage, setCurrentPage] = useState(1);
    const [events, setEvents] = useState([]);
    const [businessName, setBusinessName] = useState('');

    const url = api;

    const getBusinessInfo = async () => {
        try {
            const response = await fetch(url + 'app/businessSelection/' + user.businessId);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) setBusinessName(data.business.businessName);

        } catch (error) {
            console.error('Error fetching weather data:', error);
        }
    };


    const getUserEvents = async () => {
        try {
            const response = await fetch(url + 'app/userEvents/' + businessName);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) setEvents(data.events);

        } catch (error) {
            console.error('Error fetching events data:', error);
        }
    }

    useEffect(() => {
        getBusinessInfo();
        getUserEvents();
    }, [businessName]);


    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return events.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, events]);



    const items = currentTableData.map((event) => {
        return <UserEventCard userInfo={user} event={event} />;
    });

    return (
        <>
            <HeadingTwo title={'Manage Your Events'} className={''}/>
            <div className={'mb-10'}>
                <Button href={'/events-workshops/create-event'} text={'Create an Event'}/>
            </div>
            <div className={'bg-light-green mb-10 ' + style.cardOffers}>
                <HeadingTwo title={'Your Created Events'} className={''}/>
                {
                    items.length ?
                        <div className={styles.grid}>{items}</div>
                        :
                        <p>You did not create an event yet. Create one to see it appears here.</p>
                }
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={events.length}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </>

    );
}