import React from "react";
import HeadingOne from "../../components/towncrier/ui/heading-one";
import HeadingTwo from "../../components/towncrier/ui/heading-two";
import {Link} from "react-router-dom";
import logo from "../../assets/img/towncrier-logo.png";

export default function PrivacyPolicy() {
    return (
        <main className={'privacy-policy'}>
            <div className={'bg-green'}>
                <div className='section-pp'>
                    <Link to="/">
                        <img width={80} height={72} src={logo} alt="TownCrier logo" loading="lazy"
                             decoding="async"/>
                    </Link>
                </div>
            </div>
            <div className='section'>
                <HeadingOne className={''} title={'Privacy Policy'}/>
                <br/>
                <HeadingTwo className={''} title={'Introduction'}/>
                <p>OmniHyper Ireland ("we," "us," or "our") is committed to protecting the privacy and security of your
                    Personal Information when you use our B2B mobile application and website ("the Services"). This
                    Privacy Policy explains the types of information we collect, how we use and protect it, and your
                    choices regarding this data.</p>
                <br/>
                <HeadingTwo className={''} title={'Scope'}/>
                <p>This Privacy Policy applies to all B2B users of our Services operating on an invite-only basis.</p>
                <br/>
                <HeadingTwo className={''} title={'Information We Collect'}/>
                <ul>
                    <li><span>Information You Provide:</span>
                        <ul>
                            <li><span>Business Contact Details:</span> Name, email address, company name, job title, phone number
                            </li>
                            <li><span>Account Information:</span> Username, password</li>
                            <li><span>Content and Communications:</span> Information you post, upload, or submit through the
                                Services.
                            </li>
                        </ul>
                    </li>
                    <li><span>Information Collected Automatically:</span>
                        <ul>
                            <li><span>Device and Usage Data:</span> IP address, device identifiers, browser type, operating system,
                                location data (approximate), and how you interact with the Services.
                            </li>
                            <li><span>Cookies and Similar Technologies:</span> We may use cookies, web beacons, and similar
                                technologies for session management, security, user experience, and analytics.
                            </li>
                        </ul>
                    </li>
                </ul>
                <br/>
                <HeadingTwo className={''} title={'How We Use Your Information'}/>
                <ul>
                    <li><span>Providing the Services:</span> Operating the Services, personalizing your experience, and communicating
                        with you.
                    </li>
                    <li><span>Improving the Services:</span> Analytics to enhance the user experience and guide future development.
                    </li>
                    <li><span>Security:</span> Protecting the Services, users, and our company, including fraud detection and
                        prevention.
                    </li>
                    <li><span>Legal Compliance:</span> Meeting legal and regulatory obligations.</li>
                    <li><span>Marketing and Promotions:</span> With your consent, we may send marketing communications about our
                        Services.
                    </li>
                </ul>
                <br/>
                <HeadingTwo className={''} title={'How We Share Information'}/>
                <ul>
                    <li><span>Service Providers:</span> We share limited, relevant information with service providers to help us
                        operate the Services (e.g., cloud hosting, analytics).
                    </li>
                    <li><span>Business Transfers:</span> In the event of a merger, sale, or business reorganization, we may transfer
                        your information as part of the transaction process.
                    </li>
                    <li><span>Legal Requirements:</span> We may disclose your information as required by law, court order, or
                        official requests to protect ourselves or others.
                    </li>
                </ul>
                <br/>
                <HeadingTwo className={''} title={'Data Storage and Security'}/>
                <ul>
                    <li><span>Location:</span> User data is stored within the European Union (AWS).
                    </li>
                    <li><span>Security Measures:</span> We implement technical, organizational, and administrative measures in line
                        with industry standards to safeguard your Personal Information. However, please note that no
                        data transmission over the internet can be fully guaranteed.
                    </li>
                </ul>
                <br/>
                <HeadingTwo className={''} title={'Your Rights under the GDPR'}/>
                <p>You have certain rights regarding your data:</p>
                <ul>
                    <li><span>Access and Portability:</span> You can request to receive a copy of your Personal Information.</li>
                    <li><span>Rectification:</span> You can request the correction of inaccurate information .</li>
                    <li><span>Erasure (“Right to be Forgotten”):</span> Under specific circumstances, you may request the deletion of
                        your information.
                    </li>
                    <li><span>Restriction of Processing:</span> You may request that we limit the processing of your information in
                        certain situations.
                    </li>
                    <li><span>Objection:</span> You may object to certain processing activities, including direct marketing
                        communications.
                    </li>
                </ul>
                <br/>
                <HeadingTwo className={''} title={'Exercising Your Rights'}/>
                <p>Please contact us at <Link to={'mailto:info@omnihyper.com'}>info@omnihyper.com</Link> to exercise
                    your rights under the GDPR.</p>
                <br/>
                <HeadingTwo className={''} title={'Children\'s Privacy'}/>
                <p>Our Services are strictly intended for B2B use, not for individuals under 18. We do not knowingly
                    collect personal data from children.</p>
                <br/>
                <HeadingTwo className={''} title={'Changes to this Policy'}/>
                <p>If we make changes to this Privacy Policy, we will notify you via email or a prominent notice on the
                    Services. The effective date of the updated policy will be indicated.</p>
                <br/>
                <HeadingTwo className={''} title={'Contact Us'}/>
                <p>If you have questions or concerns about our Privacy Policy, please contact us at <Link
                    to={'mailto:info@omnihyper.com'}>info@omnihyper.com</Link></p>
                <br/>
                <p>Effective date: 1 March 2024</p>
            </div>
        </main>
    );
}