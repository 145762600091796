import React, {useState} from "react";
import Heading from "../../components/towncrier/ui/heading";
import Button from "../../components/towncrier/ui/button";
import Jobs from "../../components/towncrier/jobs";

export default function JobListings() {
    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    return (
        <main className={'job-listing towncrier-content'}>
            <div className='section'>
                <Heading title={'Job Listings'} />
                <div className={'mb-10 mt-10 members-button'}>
                    <span className={'mr-5'}><Button href={'create-job'} text={'Submit a Job Listing'}/></span>
                    {user.role === 1 ? <Button href={'manage-jobs'} text={'Manage Job Listings'}/> : ''}
                </div>
                <Jobs />
            </div>
        </main>
    );
}