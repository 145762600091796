import React from "react";
import Heading from "../../components/towncrier/ui/heading";
import CreateOfferForm from "../../components/towncrier/create-offer-form";

export default function CreateOffer() {
    return (
        <main className={'create-offer towncrier-content'}>
            <div className='section'>
                <Heading title={'Create an Offer'}/>
                <CreateOfferForm/>
            </div>
        </main>
    );
}