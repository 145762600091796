import React from "react";
import Heading from "../../components/towncrier/ui/heading";
import CreateAdminForm from "../../components/towncrier/create-admin-form";

export default function CreateAdmin() {
    return (
        <main className={'create-admin towncrier-content'}>
            <div className='section'>
                <Heading title={'Create a New Administrator'}/>
                <CreateAdminForm/>
            </div>
        </main>
    );
}