import React from "react";
import Heading from "../../components/towncrier/ui/heading";
import UpdateBusiness from "../../components/towncrier/update-business";

export default function BusinessProfile() {

    return (
        <main className={'business-profile towncrier-content'}>
            <div className='section'>
                <Heading title={'Business Profile'} />
                <p>Manage your business profile which appears on our Business Directory</p>
                <UpdateBusiness />
            </div>
        </main>
    );
}