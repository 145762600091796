import React, {useState} from "react";
import Heading from "../../components/towncrier/ui/heading";
import ManageOffers from "../../components/towncrier/manage-offers";
import ManageEvents from "../../components/towncrier/manage-events";
import Announcements from "../../components/towncrier/announcements";
import ManageJobs from "../../components/towncrier/manage-jobs";

export default function Settings() {
    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    return (
        <main className={'settings towncrier-content'}>
            <div className='section'>
                <Heading title={'Settings'} />
                {
                    user.role === 1 ?
                        <p>Manage your credentials, created events, offers and job listings as well as announcements.</p>
                        :
                        <p>Manage your credentials, created events, created offers and job listings</p>
                }
                {user.role === 1 ? <Announcements /> : ''}
                <ManageEvents />
                <ManageJobs />
                <ManageOffers />
            </div>
        </main>
    );
}