import React, {useEffect, useMemo, useState} from 'react';
import styles from "./all-events.module.css";
import style from "./events-offers.module.css";
import HeadingTwo from "./ui/heading-two";
import api from "../../api";
import Button from "./ui/button";
import Pagination from "../pagination";
import StatusJobCard from "./ui/status-job-card";

let PageSize = 6;

export default function ManageJobs() {

    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    const [currentPage, setCurrentPage] = useState(1);
    const [jobs, setJobs] = useState([]);

    const url = api;

    const getUserJobs = async () => {
        try {
            const response = await fetch(url + 'app/userJobs/' + user.businessId);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) setJobs(data.jobs);

        } catch (error) {
            console.error('Error fetching jobs data:', error);
        }
    }

    useEffect(() => {
        getUserJobs();
    }, []);


    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return jobs.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, jobs]);



    const items = currentTableData.map((job) => {
        return <StatusJobCard job={job} userInfo={user} />;
    });

    return (
        <>
            <HeadingTwo title={'Manage Your Job Listings'} className={''}/>
            <div className={'mb-10'}>
                <Button href={'/job-listing/create-job'} text={'Create a Job Listing'}/>
            </div>
            <div className={'bg-light-green mb-10 ' + style.cardOffers}>
                <HeadingTwo title={'Your Created Job Listings'} className={''}/>
                {
                    items.length ?
                        <div className={styles.grid}>{items}</div>
                        :
                        <p>You did not create a job listing yet. Create one to see it appears here.</p>
                }
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={jobs.length}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </>

    );
}