import React, {useState} from "react";
import Heading from "../../components/towncrier/ui/heading";
import PendingEvents from "../../components/towncrier/pending-events";
import {Navigate} from "react-router-dom";
import DeniedEvents from "../../components/towncrier/denied-events";

export default function EventManagement() {
    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    if (user.role !== 1) {
        return <Navigate to={'/'}/>
    }
    return (
        <main className={'event-management towncrier-content'}>
            <div className='section'>
                <Heading title={'Events Management'} />
                <PendingEvents user={user} />
                <DeniedEvents  user={user} />
            </div>
        </main>
    );
}