import React from "react";
import {useLocation, useParams} from "react-router-dom"
import Heading from "../../components/towncrier/ui/heading";
import styles from "../../components/towncrier/ui/resource-card.module.css";
import Button from "../../components/towncrier/ui/button";

export default function ResourcePage() {

    let params = useParams();
    let location = useLocation();

    return (
        <main className={'business-page towncrier-content ' + params.slug}>
            <div className={'section'}>
                <Heading title={location.state.title}/>
                <p className={styles.type}><span>{location.state.type}</span></p>
                <p>
                    {location.state.content.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br/>
                        </React.Fragment>
                    ))}
                </p>
                <div className={'align-center mt-10'}>
                    <Button text={'Go Back'} href={'..'} />
                </div>

            </div>
        </main>
    );
}