import React from "react";
import Heading from "../../components/towncrier/ui/heading";
import DashInfo from "../../components/towncrier/dash-info";
import EventsOffers from "../../components/towncrier/events-offers";

export default function DashBoard() {

    return (
        <main className={'dashboard towncrier-content'}>
            <div className='section'>
                <Heading title={'Dashboard'} />
                <DashInfo />
                <EventsOffers />
            </div>
        </main>
    );
}