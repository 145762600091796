import React, {useState} from "react";
import Heading from "../../components/towncrier/ui/heading";
import SearchDirectory from "../../components/towncrier/search-directory";
import {Navigate} from "react-router-dom";
import Button from "../../components/towncrier/ui/button";

export default function MembersDirectory() {
    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    if (user.role !== 1) {
        return <Navigate to={'/'}/>
    }
    return (
        <main className={'members-management towncrier-content'}>
            <div className='section'>
                <Heading title={'Manage your Members'} />
                <div className={'mb-10 members-button'}>
                    <span className={'mr-5'}><Button text={'Add a New Member'} href={'create-member'}/></span>
                    <Button text={'Add a New Administrator'} href={'create-admin'}/>
                </div>
                <SearchDirectory/>
            </div>
        </main>
    );
}