import React, {useEffect, useMemo, useState} from 'react';
import styles from './all-events.module.css'
import Pagination from "../pagination";
import CardEvent from "./ui/card-event";
import api from "../../api";

let PageSize = 12;

export default function AllEvents({user}) {
    const [currentPage, setCurrentPage] = useState(1);
    const [events, setEvents] = useState([]);

    const url = api;

    const getAllEvents = async () => {
        try {
            const response = await fetch(url + 'app/allEvents');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) setEvents(data.events);

        } catch (error) {
            console.error('Error fetching announcements data:', error);
        }
    }

    useEffect(() => {
        getAllEvents();
    }, []);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return events.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, events]);


    const items = currentTableData.map((event) => {
        return <CardEvent event={event} user={user}/>;
    });

    return <>
        {
            items.length ?
                <>
                    <div className={styles.grid}>{items}</div>

                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={events.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </> :
                <p>No upcoming events at this time. Thank you for your understanding.</p>
        }
    </>;
}