import React, {useState} from 'react';
import styles from "./ui/button.module.css";
import style from './login-form.module.css'
import {Navigate} from "react-router-dom";
import api from "../../api";
import {useAuth} from "../../Auth";

export default function LoginForm() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [error,setError]=useState();
    const { setAuthTokens } = useAuth();

    const url = api;

    function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);

        // Or you can work with it as a plain object:
        const formJson = Object.fromEntries(formData.entries());

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/loginAuth', options)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    localStorage.setItem('user', JSON.stringify(data.business));
                    setAuthTokens(data.business);
                    setLoggedIn(true);
                } else {
                    setError('We couldn’t find an account matching the email and password you entered. Please check your email and password and try again.')
                }
            })
            .catch(error => console.error(error));
    }

    if (isLoggedIn) {
        return <Navigate to={'/'} />;
    }

    return (
        <form method="post" onSubmit={handleSubmit} className={style.mailingForm}>
            <label>
                Email <input className={style.mailingInput + ' search-input'}
                             placeholder='Email' required={true} type='email' name="email" value={email}
                             onChange={e => setEmail(e.target.value)}/>
            </label>
            <label>
                Password <input className={style.mailingInput + ' search-input'} placeholder='Password' type='password'
                                name="password" value={name}
                                onChange={e => setName(e.target.value)}/>
            </label>
            <button className={styles.button} type="submit">Sign in</button>
            {error?<p className={style.error}>{error}</p>:null}
        </form>
    );
}