import React, {useState} from 'react';
import styles from './join-event-modal.module.css'
import HeadingTwo from "./ui/heading-two";
import style from "./update-business.module.css";
import api from "../../api";

export default function EditOfferModal({show, handleClose, offer, userInfo}) {

    const [discount, setDiscount] = useState(offer.discount);
    const [code, setCode] = useState(offer.code);
    const [validity, setValidity] = useState(offer.validity.replace('Z', ''));
    const [user] = useState(userInfo);
    const [error, setError] = useState('');

    const url = api;

    if (!show) {
        return null;
    }
    function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);

        // Or you can work with it as a plain object:
        const formJson = Object.fromEntries(formData.entries());
        formJson.id = offer.id;
        formJson.businessId = user.businessId;

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/updateOffer', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Your offer got updated!");
                    handleClose()
                } else {
                    setError('Your offer did not get updated. Please check the information entered and try again.')
                }
            })
            .catch(error => console.error(error));

    }

    return (
        <div className={styles.modal}>
            <div className={styles.modalMain}>
                <div className={'section-col ' + styles.header}>
                    <HeadingTwo className={styles.heading} title={'Edit your Offer'}/>
                    <button className={styles.close} onClick={handleClose} >Close</button>
                </div>

                <form method="post" onSubmit={handleSubmit} className={style.mailingForm}>
                    <label>
                        Discount <input className={style.mailingInput + ' search-input '} placeholder='10%'
                                        required={true} type='text' name="discount" value={discount}
                                        onChange={e => setDiscount(e.target.value)}/>
                    </label>
                    <label>
                        Code <input className={style.mailingInput + ' search-input '} placeholder='MEMBER10'
                                    required={true} onChange={e => setCode(e.target.value)}
                                    type='text' name="code" value={code}/>
                    </label>
                    <label>
                        Validity <input className={style.mailingInput + ' search-input '} placeholder='Validity'
                                        required={true} type='datetime-local' name="validity" value={validity}
                                        onChange={e => setValidity(e.target.value)}/>
                    </label>
                    <div className={'align-center'}>
                        <button className={styles.button} type="submit">Submit</button>
                        {error ? <p className={style.error}>{error}</p> : null}
                    </div>
                </form>

            </div>
        </div>
    )
}