import React, {useEffect, useState} from "react";
import {FaSearch} from "react-icons/fa";
import ResourceCard from "./ui/resource-card";
import styles from './filter-resources.module.css'
import api from "../../api";

export default function FilterResources() {
    const options = [
        {value: '', text: 'All'},
        {value: 'Guide', text: 'Guide'},
        {value: 'Resource', text: 'Resource'},
        {value: 'Template', text: 'Template'},
    ];
    /*
        const resources = [
            {
                id: 0,
                title: 'The Business Model Canvas',
                type: 'Resource',
                content: 'The Business Model Canvas is a powerful tool that entrepreneurs and business leaders use to visualize, analyze, and fine-tune their business concepts. Developed by Alexander Osterwalder, the Business Model Canvas provides a holistic view of a business, encapsulating key components that drive its success. This resource aims to delve into the intricacies of the Business Model Canvas, offering a comprehensive guide to help businesses harness its potential for strategic planning and innovation.\n' +
                    '\n' +
                    'Section 1: Understanding the Business Model Canvas\n' +
                    '\n' +
                    '1.1 What is the Business Model Canvas?\n' +
                    '\n' +
                    'The Business Model Canvas is a one-page framework that condenses a business\'s key components into nine building blocks. These blocks include Customer Segments, Value Propositions, Channels, Customer Relationships, Revenue Streams, Key Resources, Key Activities, Key Partnerships, and Cost Structure.\n' +
                    '\n' +
                    '1.2 Why Use the Business Model Canvas?\n' +
                    '\n' +
                    'The simplicity and visual nature of the Business Model Canvas make it an invaluable tool for entrepreneurs and established businesses alike. By providing a snapshot of the entire business model on a single page, it fosters collaboration, strategic thinking, and a deeper understanding of how each element contributes to overall success.\n' +
                    '\n' +
                    'Section 2: The Nine Building Blocks\n' +
                    '\n' +
                    '2.1 Customer Segments\n' +
                    '\n' +
                    'Identify and define the various groups of people or organizations your business aims to serve. Understanding your target audience is fundamental to tailoring your products or services to meet their specific needs.\n' +
                    '\n' +
                    '2.2 Value Propositions\n' +
                    '\n' +
                    'Articulate the unique value your business offers to customers. What sets your products or services apart? A compelling value proposition is key to attracting and retaining customers.\n' +
                    '\n' +
                    '2.3 Channels\n' +
                    '\n' +
                    'Examine the different ways your business delivers its value propositions to customers. This block encompasses your distribution channels, sales channels, and communication channels.\n' +
                    '\n' +
                    '2.4 Customer Relationships\n' +
                    '\n' +
                    'Explore the type of relationship your business establishes with its customers. Whether it\'s through personal assistance, self-service, or automated services, understanding customer relationships is vital to customer satisfaction and loyalty.\n' +
                    '\n' +
                    '2.5 Revenue Streams\n' +
                    '\n' +
                    'Identify the various sources of revenue for your business. This can include one-time sales, subscription models, licensing fees, or other innovative approaches to monetization.\n' +
                    '\n' +
                    '2.6 Key Resources\n' +
                    '\n' +
                    'List the critical assets and resources your business needs to deliver its value propositions, reach customers, and generate revenue. This includes physical, intellectual, human, and financial resources.\n' +
                    '\n' +
                    '2.7 Key Activities\n' +
                    '\n' +
                    'Outline the key actions and processes necessary to create and deliver value to customers. This block encompasses everything from production and marketing to distribution and customer support.\n' +
                    '\n' +
                    '2.8 Key Partnerships\n' +
                    '\n' +
                    'Identify strategic alliances and collaborations that can help your business leverage external expertise, resources, or reduce risks. Building strong partnerships can enhance your capabilities and reach.\n' +
                    '\n' +
                    '2.9 Cost Structure\n' +
                    '\n' +
                    'Analyze the fixed and variable costs associated with operating your business. Understanding your cost structure is crucial for setting pricing strategies and ensuring profitability.\n' +
                    '\n' +
                    'Section 3: Practical Tips for Implementing the Business Model Canvas\n' +
                    '\n' +
                    '3.1 Collaborative Workshop Sessions\n' +
                    '\n' +
                    'Organize collaborative workshops involving key stakeholders to fill in the Business Model Canvas. This fosters a shared understanding of the business model and encourages diverse perspectives.\n' +
                    '\n' +
                    '3.2 Iterative Process\n' +
                    '\n' +
                    'View the Business Model Canvas as a dynamic tool that evolves over time. Regularly revisit and update it as your business adapts to market changes, customer feedback, and internal developments.\n' +
                    '\n' +
                    '3.3 Testing and Validation\n' +
                    '\n' +
                    'Use the Business Model Canvas as a foundation for testing assumptions and validating hypotheses. This iterative process helps refine your business model and increases the likelihood of success.\n' +
                    '\n' +
                    '\n' +
                    'The Business Model Canvas serves as a compass for businesses navigating the complexities of the modern marketplace. By breaking down the intricacies of a business into nine digestible building blocks, this framework empowers entrepreneurs and business leaders to make informed decisions, drive innovation, and cultivate sustainable growth. Embrace the power of the Business Model Canvas to chart your course towards success in today\'s dynamic business landscape.',
                slug: 'business-model-canvas',
            },
            {
                id: 1,
                title: '10 Tips for Getting your Business Ready for Christmas',
                type: 'Guide',
                content: 'The holiday season is just around the corner, and as a business owner, it\'s crucial to prepare your venture for the festive rush. Christmas is a time when consumer spending peaks, providing an excellent opportunity to boost sales and enhance customer satisfaction. To ensure your business is ready to make the most of this festive season, here are 10 essential tips to consider.\n' +
                    '\n' +
                    'Plan Early:\n' +
                    'Start your preparations well in advance to avoid last-minute chaos. Create a detailed timeline outlining when to launch promotions, update inventory, and decorate your storefront. Early planning allows you to anticipate potential challenges and ensures a smooth holiday season.\n' +
                    '\n' +
                    'Optimize Your Online Presence:\n' +
                    'In today\'s digital age, online visibility is key. Update your website, product listings, and social media profiles with festive themes and special offers. Consider offering online-exclusive promotions to attract a broader audience and drive online sales.\n' +
                    '\n' +
                    'Decorate Your Physical Space:\n' +
                    'Create a festive atmosphere in your brick-and-mortar store to attract customers. Incorporate Christmas decorations, window displays, and themed signage. A well-decorated store not only enhances the customer experience but also sets the mood for holiday shopping.\n' +
                    '\n' +
                    'Stock Up on Inventory:\n' +
                    'Analyze past sales data and market trends to predict the demand for your products during the holiday season. Ensure you have sufficient inventory to meet the increased demand and consider offering exclusive holiday-themed items to entice shoppers.\n' +
                    '\n' +
                    'Launch Special Promotions:\n' +
                    'Entice customers with exclusive Christmas promotions and discounts. Consider bundle deals, buy-one-get-one-free offers, or loyalty programs to encourage repeat business. Advertise these promotions through various channels, including social media, email newsletters, and in-store signage.\n' +
                    '\n' +
                    'Provide Excellent Customer Service:\n' +
                    'The holiday season can be stressful for shoppers, so ensure your staff is well-trained and prepared to provide excellent customer service. Offer extended store hours, a hassle-free return policy, and knowledgeable staff to assist customers with their purchases.\n' +
                    '\n' +
                    'Offer Gift Wrapping Services:\n' +
                    'Simplify the gift-giving process for your customers by offering gift wrapping services. This additional touch can set your business apart and create a positive impression, encouraging customers to choose your store for their holiday shopping.\n' +
                    '\n' +
                    'Implement an Efficient Checkout Process:\n' +
                    'Long lines and slow checkout processes can deter customers. Streamline your checkout process by adding extra cash registers, implementing mobile payment options, and training staff to efficiently handle transactions.\n' +
                    '\n' +
                    'Leverage Email Marketing:\n' +
                    'Utilize your email list to stay in touch with customers and promote your holiday offers. Send out regular newsletters featuring upcoming promotions, new products, and exclusive discounts. Personalize your emails to make customers feel valued and appreciated.\n' +
                    '\n' +
                    'Express Gratitude:\n' +
                    'Show appreciation to your customers by expressing gratitude for their support throughout the year. Consider sending holiday cards, offering exclusive discounts to loyal customers, or organizing customer appreciation events. Building a strong relationship with your customer base can lead to long-term loyalty.\n' +
                    '\n' +
                    'By implementing these 10 tips, your business can maximize its potential during the Christmas season. Whether you operate online or have a physical storefront, thoughtful planning, engaging promotions, and excellent customer service will contribute to a successful and festive holiday experience for your business and your customers alike.',
                slug: '10-tips-for-getting-your-business-ready-for-christmas',
            },
            {
                id: 2,
                type: "Resource",
                title: "SWOT Analysis: A Comprehensive Guide",
                slug: "swot-analysis-comprehensive-guide",
                content: "SWOT Analysis is a strategic planning tool used by businesses to identify and understand their Strengths, Weaknesses, Opportunities, and Threats. This comprehensive guide explores the process of conducting a SWOT Analysis, its significance in decision-making, and how businesses can leverage the insights gained to enhance their overall strategy and performance."
            },
            {
                id: 3,
                type: "Guide",
                title: "Digital Marketing Strategies for Small Businesses",
                slug: "digital-marketing-strategies-for-small-businesses",
                content: "This guide provides small business owners with practical and effective digital marketing strategies to enhance online visibility, attract target audiences, and increase customer engagement. Learn about social media marketing, search engine optimization (SEO), email campaigns, and other digital tools to propel your small business into the digital landscape successfully."
            },
            {
                id: 4,
                type: "Resource",
                title: "Financial Planning 101: A Beginner's Guide",
                slug: "financial-planning-101-beginners-guide",
                content: "Financial planning is essential for the success and sustainability of any business. This beginner's guide covers the basics of financial planning, including budgeting, forecasting, and financial statement analysis. Gain insights into creating a solid financial foundation for your business and making informed financial decisions."
            },
            {
                id: 5,
                type: "Guide",
                title: "Effective Team Building Strategies",
                slug: "effective-team-building-strategies",
                content: "Building a strong and cohesive team is crucial for business success. This guide explores proven strategies for effective team building, including communication techniques, leadership development, and fostering a positive team culture. Learn how to create a collaborative and motivated team that drives productivity and innovation."
            },
            {
                id: 6,
                type: "Resource",
                title: "The Art of Negotiation in Business",
                slug: "art-of-negotiation-in-business",
                content: "Negotiation skills are paramount in the business world. This resource delves into the art of negotiation, providing insights into preparation, effective communication, and strategies for achieving favorable outcomes. Whether negotiating deals, partnerships, or contracts, mastering the art of negotiation is a key asset for business professionals."
            },
            {
                id: 7,
                type: "Guide",
                title: "Strategies for Sustainable Business Practices",
                slug: "strategies-for-sustainable-business-practices",
                content: "Sustainability is becoming increasingly important in the business landscape. This guide explores strategies for implementing sustainable practices in your business, covering areas such as supply chain management, eco-friendly initiatives, and corporate social responsibility. Discover how embracing sustainability can benefit both your business and the environment."
            },
            {
                id: 8,
                type: "Resource",
                title: "Cybersecurity Best Practices for Small Businesses",
                slug: "cybersecurity-best-practices-for-small-businesses",
                content: "Small businesses are often targets for cyber threats. This resource provides a comprehensive overview of cybersecurity best practices tailored for small businesses. Learn how to protect your company's sensitive information, secure online transactions, and implement robust cybersecurity measures to safeguard your business from potential threats."
            },
            {
                id: 9,
                type: "Guide",
                title: "The Power of Branding: Building a Strong Business Identity",
                slug: "power-of-branding-building-strong-business-identity",
                content: "Branding is a vital aspect of business success. This guide explores the power of branding and offers practical tips for building a strong business identity. Learn how to create a compelling brand message, establish brand consistency, and connect with your target audience on a deeper level through effective branding strategies."
            },
            {
                id: 10,
                type: "Resource",
                title: "Innovative Product Development Strategies",
                slug: "innovative-product-development-strategies",
                content: "Staying competitive in the market requires continuous innovation. This resource dives into innovative product development strategies, covering ideation, market research, prototyping, and launch. Explore ways to foster a culture of innovation within your business and bring cutting-edge products to market successfully."
            },
            {
                id: 11,
                type: "Guide",
                title: "Human Resources Management Essentials",
                slug: "human-resources-management-essentials",
                content: "Effective human resources management is critical for organizational success. This guide provides essential insights into HR practices, including recruitment, employee development, performance management, and legal compliance. Learn how to create a positive work environment and cultivate a motivated and engaged workforce."
            },
            {
                id: 12,
                type: "Resource",
                title: "Market Expansion Strategies: A Comprehensive Approach",
                slug: "market-expansion-strategies-comprehensive-approach",
                content: "Expanding into new markets is a significant growth opportunity for businesses. This resource offers a comprehensive approach to market expansion strategies, covering market analysis, entry tactics, and risk management. Gain valuable insights into successfully entering and thriving in new markets."
            },
            {
                id: 13,
                type: "Guide",
                title: "Crisis Management: Navigating Business Challenges",
                slug: "crisis-management-navigating-business-challenges",
                content: "Every business faces crises at some point. This guide explores effective crisis management strategies to navigate challenges and maintain business continuity. Learn how to develop a crisis response plan, communicate transparently with stakeholders, and emerge stronger from unexpected setbacks."
            },
            {
                id: 14,
                type: "Resource",
                title: "Strategic Leadership in the Modern Business Landscape",
                slug: "strategic-leadership-in-modern-business-landscape",
                content: "Leadership plays a pivotal role in business success. This resource focuses on strategic leadership in the modern business landscape, addressing adaptive leadership styles, decision-making in uncertainty, and fostering innovation. Gain insights into leading your organization strategically in an ever-evolving business environment."
            }
        ];
    */
    const [resources, setResources] = useState([]);
    const [selected, setSelected] = useState(options[0].value);
    const [input, setInput] = useState("");
    //const [results, setResults] = useState(resources);
    const [results, setResults] = useState([]);
    const [filtered, setFiltered] = useState([]);

    const url = api;

    const getAllResources = async () => {
        try {
            const response = await fetch(url + 'app/allResources');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setResources(data.resources);
            setResults(data.resources)

        } catch (error) {
            console.error('Error fetching announcements data:', error);
        }
    }

    useEffect(() => {
        getAllResources();
    }, []);

    const fetchDataInput = (value) => {
        if (value === '') {
            setResults(resources);
            return;
        }
        const res = resources.filter((resource) => {
            return (
                value &&
                resource &&
                resource.title &&
                resource.title.toLowerCase().includes(value)
            );
        });
        setResults(res);
    };

    const fetchDataSelect = (value) => {
        if (value === '') {
            setResults(resources);
            return;
        }
        const res = resources.filter((resource) => {
            return (
                value &&
                resource &&
                resource.type &&
                resource.type.includes(value)
            );
        });
        setResults(res);
    };

    const handleChangeSelect = event => {
        setSelected(event.target.value);
        setInput('');
        fetchDataSelect(event.target.value);
    };

    const handleChangeInput = event => {
        setInput(event);
        fetchDataInput(event);
    };

    useEffect(() => {
        if (results.length > 0) {
            let newList = [...results];
            if (input) {
                newList = newList.filter(i => i.title.toLowerCase().includes(input));
            }
            if (selected) {
                newList = newList.filter(i => i.type.includes(selected));
            }
            setFiltered(newList);
        } else {
            setFiltered(results);
        }
    }, [results, input, selected]);

    return (
        <>
            <div className={'search-container section-col'}>
                <div className={'filter-wrapper search-input mb-5 mb-0d'}>
                    <select id={'filter'} className={''} value={selected} onChange={handleChangeSelect}>
                        {options.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.text}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={'search-wrapper search-input'}>
                    <input id='search-input' placeholder="Search Resources or guides" value={input}
                           onChange={(e) => handleChangeInput(e.target.value)}
                    />
                    <FaSearch size={20} className="search-icon"/>
                </div>
            </div>
            <div className={'search-results'}>
                {filtered.length > 0 ?
                    <div className={styles.grid}>
                        {
                            filtered.map(filter => (
                                <div className={styles.col} key={filter.id}>
                                    <ResourceCard id={filter.id}
                                                  title={filter.title}
                                                  content={filter.content}
                                                  type={filter.type}
                                    /></div>

                            ))
                        }
                    </div>
                    :
                    <p>No results found. Please try again.</p>
                }
            </div>
        </>

    );
}