import React, {useState} from "react";
import Heading from "../../components/towncrier/ui/heading";
import CreateResourceForm from "../../components/towncrier/create-resource-form";
import {Navigate} from "react-router-dom";

export default function CreateResource() {
    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    if (user.role !== 1) {
        return <Navigate to={'/'}/>
    }
    return (
        <main className={'create-resource towncrier-content'}>
            <div className='section'>
                <Heading title={'Create a Resource'}/>
                <CreateResourceForm userLocation={user.location}/>
            </div>
        </main>
    );
}