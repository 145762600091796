import React, {useState} from 'react';
import styles from './ui/button.module.css'
import HeadingTwo from "./ui/heading-two";
import AnnouncementModal from "./announcement-modal";

export default function Announcements() {
    const [show, setShow] = useState(false);

    const showModal = () => {
        setShow(true);
    };
    const hideModal = () => {
        setShow(false);
    };

    return (
        <>
            <HeadingTwo title={'Announcement'} className={''}/>
            <div className={'mb-10'}>
                <button className={styles.button} onClick={e => {showModal();}}>Create an Announcement</button>
            </div>
            <AnnouncementModal show={show} handleClose={e => {hideModal();}}/>
        </>

    );
}