import React, {useState} from 'react';
import styles from './join-event-modal.module.css'
import HeadingTwo from "./ui/heading-two";
import style from "./update-business.module.css";
import api from "../../api";
import Button from "./ui/button";

export default function EditEventModal({show, handleClose, event, userInfo}) {

    const [user] = useState(userInfo);
    const [error, setError] = useState('');
    const [address, setAddress] = useState(event.address);
    const [title, setTitle] = useState(event.title);
    const [shortDesc, setShortDesc] = useState(event.shortDesc);
    const [description, setDescription] = useState(event.description);
    const [startingDate, setStartingDate] = useState(event.startingDate.replace('Z', ''));
    const [endingDate, setEndingDate] = useState(event.endingDate.replace('Z', ''));
    const [status, setStatus] = useState('');

    const url = api;

    if (!show) {
        return null;
    }
    function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);

        // Or you can work with it as a plain object:
        const formJson = Object.fromEntries(formData.entries());
        formJson.eventsId = event.eventsId;
        formJson.status = status;

        const initPut = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/updateEvent', initPut)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Your event got updated!");
                    handleClose()
                } else {
                    setError('Your event did not get updated. Please check the information entered and try again.')
                }
            })
            .catch(error => console.error(error));

    }

    return (
        <div className={styles.modal}>
            <div className={styles.modalMain}>
                <div className={'section-col ' + styles.header}>
                    <HeadingTwo className={styles.heading} title={'Edit your Event'}/>
                    <button className={styles.close} onClick={handleClose} >Close</button>
                </div>
                <form method="post" onSubmit={handleSubmit} className={style.mailingForm}>
                    <div className={'section-col'}>
                        <label>
                            Event Title <input className={style.mailingInput + ' search-input '} placeholder='Title'
                                               required={true} type='text' name="title" value={title}
                                               onChange={e => setTitle(e.target.value)}/>
                        </label>
                        <label>
                            Address <input className={style.mailingInput + ' search-input'}
                                           placeholder='Address' required={true} type='text'
                                           name="address" value={address} onChange={e => setAddress(e.target.value)}/>
                        </label>
                    </div>
                    <div className={'section-col'}>
                        <label>
                            From <input className={style.mailingInput + ' search-input '} placeholder='Title'
                                        required={true} type='datetime-local' name="startingDate" value={startingDate}
                                        onChange={e => setStartingDate(e.target.value)}/>
                        </label>
                        <label>
                            To <input className={style.mailingInput + ' search-input '} placeholder='Organiser'
                                      required={true} onChange={e => setEndingDate(e.target.value)}
                                      type='datetime-local' name="endingDate" value={endingDate}/>
                        </label>
                    </div>
                    <label>
                        Short description <input className={style.mailingInput + ' search-input'}
                                                 placeholder='Short description'
                                                 type='text'
                                                 name="shortDesc" value={shortDesc}
                                                 onChange={e => setShortDesc(e.target.value)}/>
                    </label>
                    <label>
                        Description <textarea className={style.mailingInput + ' search-input'} placeholder='Description'
                                              required={true} rows='4' name="description" value={description}
                                              onChange={e => setDescription(e.target.value)}/>
                    </label>
                    <div>
                        {
                            user.role === 1 ?
                                <button className={styles.button + ' mr-5'} onClick={e => setStatus('approved')} type="submit">Publish</button>
                                :
                                <button className={styles.button + ' mr-5'} onClick={e => setStatus('pending')} type="submit">Submit</button>
                        }
                        <Button text={'Go Back'} href={'..'} />
                    </div>
                    {error ? <p className={style.error}>{error}</p> : null}
                </form>

            </div>
        </div>
    )
}