import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import React from 'react';
import styles from "./LinearProgressWithLabel.module.css"


function LinearProgressWithLabel(props) {

    return (
        <div className={styles.containerProgress}>
            <div className={styles.progressBar}>
                <LinearProgress sx={{height: 10, borderRadius: 5, [`&.${linearProgressClasses.colorPrimary}`]: {backgroundColor: '#fff'},[`& .${linearProgressClasses.bar}`]:{borderRadius: 5,backgroundColor: '#063A2E'}}}  variant="determinate" {...props} />
            </div>
            <div className={styles.progressText}>
                <p color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</p>
            </div>
        </div>
    );
}

export default LinearProgressWithLabel;