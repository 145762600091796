import React, {useState} from 'react';
import styles from './join-event-modal.module.css'
import HeadingTwo from "./ui/heading-two";
import style from "./update-business.module.css";
import api from "../../api";

export default function AnnouncementModal({show, handleClose}) {

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    const [currentDate] = useState(new Date());

    const url = api;

    if (!show) {
        return null;
    }


    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();
    let day = currentDate.getDate();
    let hour = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    let seconds = currentDate.getSeconds();

    if (month < 10) month = '0'+ month;
    if (day < 10) day = '0'+ day;
    if (hour < 10) hour = '0'+ hour;
    if (minutes < 10) minutes = '0'+ minutes;
    if (seconds < 10) seconds = '0'+ seconds;

    function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);

        // Or you can work with it as a plain object:
        const formJson = Object.fromEntries(formData.entries());
        formJson.created = `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;

        console.log(JSON.stringify(formJson))

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/handlePublish', options)
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    alert("Your announcement was published!");
                    handleClose()
                } else {
                    setError('Your announcement was not published. Please check the information entered and try again.')
                }
            })
            .catch(error => console.error(error));

    }

    return (
        <div className={styles.modal}>
            <div className={styles.modalMain}>
                <div className={'section-col ' + styles.header}>
                    <HeadingTwo className={styles.heading} title={'Create an Announcement'}/>
                    <button className={styles.close} onClick={handleClose} >Close</button>
                </div>

                <form method="post" onSubmit={handleSubmit} className={style.mailingForm}>
                    <label>
                        Announcement Title <input className={style.mailingInput + ' search-input '} placeholder='Announcement Title'
                                        required={true} type='text' name="annoucementTitle" value={title}
                                        onChange={e => setTitle(e.target.value)}/>
                    </label>
                    <label>
                        Description <input className={style.mailingInput + ' search-input '} placeholder='Description'
                                    required={true} onChange={e => setDescription(e.target.value)}
                                    type='text' name="annoucementDesc" value={description}/>
                    </label>
                    <div className={'align-center'}>
                        <button className={styles.button} type="submit">Submit</button>
                        {error ? <p className={style.error}>{error}</p> : null}
                    </div>
                </form>

            </div>
        </div>
    )
}