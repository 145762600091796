import React, {useEffect, useState} from 'react';
import styles from "./ui/button.module.css";
import style from './login-form.module.css'
import {useNavigate} from "react-router-dom";
import api from "../../api";
import Button from "./ui/button";

export default function CreateJobForm() {

    const [title, setTitle] = useState('');
    const [contact, setContact] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState('');
    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    const [error, setError] = useState('');
    const [businessName, setBusinessName] = useState('');

    const url = api;
    const navigate = useNavigate();

    const getBusinessInfo = async () => {
        try {

            const response = await fetch(url + 'app/businessSelection/' + user.businessId);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setBusinessName(data.business.businessName);
        } catch (error) {
            console.error('Error fetching business data:', error);
        }
    };

    function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);

        // Or you can work with it as a plain object:
        const formJson = Object.fromEntries(formData.entries());
        formJson.businessName = businessName;
        formJson.locationID = user.location;
        formJson.businessID = user.businessId;
        formJson.status = status;

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/createJob', options)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Job created!");
                    navigate('/job-listing');
                } else {
                    setError('We couldn’t create your job listing. Please check the information entered and try again.')
                }
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        getBusinessInfo();
    }, []);

    return (
        <form method="post" onSubmit={handleSubmit} className={style.mailingForm}>
            <label>
                Job Title <input className={style.mailingInput + ' search-input '} placeholder='Job Title'
                                required={true} type='text' name="title" value={title}
                                onChange={e => setTitle(e.target.value)}/>
            </label>
            <label>
                Contact (phone and/or email) <input className={style.mailingInput + ' search-input '} placeholder='Contact'
                            required={true} onChange={e => setContact(e.target.value)}
                            type='text' name="contact" value={contact}/>
            </label>
            <label>
                Description <textarea className={style.mailingInput + ' search-input'} placeholder='Description'
                                      required={true} rows='4' name="description" value={description}
                                      onChange={e => setDescription(e.target.value)}/>
            </label>
            <div>
                {
                    user.role === 1 ?
                        <button className={styles.button + ' mr-5'} onClick={e => setStatus('approved')}
                                type="submit">Publish</button>
                        :
                        <button className={styles.button + ' mr-5'} onClick={e => setStatus('pending')}
                                type="submit">Submit</button>
                }
                <Button text={'Go Back'} href={'..'}/>
            </div>
            {error ? <p className={style.error}>{error}</p> : null}
        </form>
    );
}