import React, {useState} from 'react'
import styles from "./pending-event-card.module.css";
import DetailsJobModal from "../details-job-modal";
import {useLocation} from "react-router-dom";

export default function StatusJobCard({job, userInfo}) {

    const [show, setShow] = useState(false);
    let location = useLocation();

    function months(month) {
        switch (month) {
            case '01':
                return 'January';
            case '02':
                return 'February';
            case '03':
                return 'March';
            case '04':
                return 'April';
            case '05':
                return 'May';
            case '06':
                return 'June';
            case '07':
                return 'July';
            case '08':
                return 'August';
            case '09':
                return 'September';
            case '10':
                return 'October';
            case '11':
                return 'November';
            case '12':
                return 'December';
            default:
                return '';
        }
    }

    const postedDate = job.created.split('T');

    const postedDay = postedDate[0].split('-');
    const postedHours = postedDate[1].split(':');

    const postedDateMonth = months(postedDay[1]);
    const postedDateYear = postedDay[0];
    const postedTime = postedHours[0] + ':' + postedHours[1];

    let jobDate = postedDateMonth.substring(0, 3) + '. ' + postedDay[2] + ', ' + postedDateYear + ' at ' + postedTime;


    const showModal = () => {
        setShow(true);
    };
    const hideModal = () => {
        setShow(false);
    };

    return (
        <div className={styles.col} key={job.jobId}>
            <div>
                <p className={styles.title}>{job.title}</p>
            </div>
            <div className={styles.footer}>
                { location.pathname === '/settings' ? <p>Job listing status: {job.status}</p> : '' }
                <p>{job.businessName}</p>
                <p>{jobDate}</p>
                <p>{job.description.substring(0, 80)}...</p>
                <span><button onClick={e => {
                    showModal();
                }}>View More</button></span>
            </div>
            <DetailsJobModal user={userInfo} showDetails={show} handleCloseDetails={e => {
                hideModal();
            }} job={job} jobDate={jobDate}
            />
        </div>
    );
}