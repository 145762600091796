import React, {useState} from "react";
import "./App.css";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import ScrollToTop from "./components/scroll-to-top";
import SideBar from "./components/towncrier/sidebar";
import Login from "./pages/towncrier/login";
import DashBoard from "./pages/towncrier/dashboard";
import BusinessProfile from "./pages/towncrier/business-profile";
import EventsWorkshops from "./pages/towncrier/events-workshops";
import ResourceLibrary from "./pages/towncrier/resource-library";
import ResourcePage from "./pages/towncrier/resource-page";
import CreateEvent from "./pages/towncrier/create-event";
import {AuthContext} from "./Auth";
import PrivateRoute from "./PrivateRoute";
import PrivacyPolicy from "./pages/towncrier/privacy-policy";
import Settings from "./pages/towncrier/settings";
import CreateOffer from "./pages/towncrier/create-offer";
import MembersDirectory from "./pages/towncrier/members-directory";
import EditBusiness from "./pages/towncrier/edit-business";
import EventManagement from "./pages/towncrier/event-management";
import CreateResource from "./pages/towncrier/create-resource";
import JobListings from "./pages/towncrier/job-listings";
import CreateJob from "./pages/towncrier/create-job";
import JobsManagement from "./pages/towncrier/jobs-management";
import CreateMember from "./pages/towncrier/create-member";
import CreateAdmin from "./pages/towncrier/create-admin";
function App() {

    const [authTokens, setAuthTokens] = useState(
        localStorage.getItem("tokens") || ""
    );
    const setTokens = (data) => {
        localStorage.setItem("tokens", JSON.stringify(data));
        setAuthTokens(data);
    };

    return (
        <AuthContext.Provider value={{authTokens, setAuthTokens: setTokens}}>
            <Router>
                <ScrollToTop/>
                <div className={"towncrier-container"}>
                    { authTokens ? <SideBar/> : '' }
                    <Routes>
                        <Route path="login" element={<Login/>}/>
                        <Route path='privacy-policy' element={<PrivacyPolicy />} />
                        <Route path="/" element={<PrivateRoute><DashBoard/></PrivateRoute>}/>
                        <Route path="business-profile" element={<PrivateRoute><BusinessProfile/></PrivateRoute>}/>
                        <Route path="events-workshops">
                            <Route index element={<PrivateRoute><EventsWorkshops/></PrivateRoute>}/>
                            <Route path="create-event" element={<PrivateRoute><CreateEvent/></PrivateRoute>}/>
                            <Route path="event-management" element={<PrivateRoute><EventManagement/></PrivateRoute>}/>
                        </Route>
                        <Route path="resource-library">
                            <Route index element={<PrivateRoute><ResourceLibrary/></PrivateRoute>}/>
                            <Route path=":resourceName" element={<PrivateRoute><ResourcePage/></PrivateRoute>}/>
                            <Route path="create-resource" element={<PrivateRoute><CreateResource/></PrivateRoute>}/>
                        </Route>
                        <Route path="settings">
                            <Route index element={<PrivateRoute><Settings/></PrivateRoute>}/>
                            <Route path="create-offer" element={<PrivateRoute><CreateOffer/></PrivateRoute>}/>
                        </Route>
                        <Route path="manage-members">
                            <Route index element={<PrivateRoute><MembersDirectory/></PrivateRoute>}/>
                            <Route path=":businessName" element={<PrivateRoute><EditBusiness/></PrivateRoute>}/>
                            <Route path="create-member" element={<PrivateRoute><CreateMember/></PrivateRoute>}/>
                            <Route path="create-admin" element={<PrivateRoute><CreateAdmin/></PrivateRoute>}/>
                        </Route>
                        <Route path="job-listing">
                            <Route index element={<PrivateRoute><JobListings/></PrivateRoute>}/>
                            <Route path="create-job" element={<PrivateRoute><CreateJob/></PrivateRoute>}/>
                            <Route path="manage-jobs" element={<PrivateRoute><JobsManagement/></PrivateRoute>}/>
                        </Route>
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                </div>
            </Router>
        </AuthContext.Provider>
    );
}

export default App;