import {GoPerson} from "react-icons/go";
import HeadingTwo from "./ui/heading-two";
import {HiOutlineMail} from "react-icons/hi";
import {IoBusinessOutline} from "react-icons/io5";
import GaugeChart from 'react-gauge-chart'
import styles from "./dash-info.module.css"
import React, {useEffect, useState} from 'react';
import sun from '../../assets/img/sun.png';
import cloudy_sunshine from '../../assets/img/cloudy_sunshine.png';
import scattered_cloud from '../../assets/img/scattered_cloud.png';
import cloudy from '../../assets/img/cloudy.png';
import light_rain from '../../assets/img/light_rain.png';
import sun_light_rain from '../../assets/img/sun_light_rain.png';
import heavy_rain from '../../assets/img/heavy_rain.png';
import snow from '../../assets/img/snowing.png';
import lightning from '../../assets/img/lightning.png';
import mist from '../../assets/img/mist.png';
import apiUrl from "../../api";
import {Link} from "react-router-dom";
import LocalisBenchmark from "./localis-benchmark";


export default function DashInfo() {

    const [description, setDescription] = useState('');
    const [weatherImage, setWeatherImage] = useState(scattered_cloud);
    const [weatherTemp, setWeatherTemp] = useState('');
    const [weatherTempFeels, setWeatherTempFeels] = useState('');
    const [date] = useState(new Date());
    const [waterLevel, setWaterLevel] = useState('0');
    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    const [businessName, setBusinessName] = useState('');
    const [membershipStatus, setMembershipStatus] = useState('');
    const [membersNumber, setMembersNumber] = useState(0);
    const [awaitingEvents, setAwaitingEvents] = useState(0);
    const [pendingJobs, setPendingJobs] = useState(0);

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    let currentMonth = monthNames[date.getMonth()];
    let currentDay = date.getDate();
    let currentYear = date.getFullYear();

    const getWeather = async () => {
        try {
            const lat = '51.91474382228282';
            const lon = '-8.172941224515434';
            const key = '787abfc3aa2d1b414feec343099f7dfa';
            const response = await fetch(
                `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${key}`
            );

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const weather_description = data.weather[0].description;
            const weather_temp = data.main.temp - 273.15;
            const weather_temp_feel = data.main.feels_like - 273.15;
            setWeatherTempFeels(`${weather_temp_feel.toFixed(1)}°C`)
            setWeatherTemp(`${weather_temp.toFixed(1)}°C`);
            setDescription(weather_description.replace(/\b\w/g, (match) => match.toUpperCase()));

            if (data.weather[0].description === 'clear sky') {
                setWeatherImage(sun);
            }
            if (data.weather[0].description === 'few clouds') {
                setWeatherImage(cloudy_sunshine);
            }
            if (data.weather[0].description === 'scattered clouds' || data.weather[0].description === 'broken clouds') {
                setWeatherImage(scattered_cloud);
            }
            if (data.weather[0].description === 'overcast clouds') {
                setWeatherImage(cloudy);
            }
            if (data.weather[0].description === 'drizzle') {
                setWeatherImage(light_rain);
            }
            if (data.weather[0].description === 'rain') {
                setWeatherImage(sun_light_rain);
            }
            if (data.weather[0].description === 'shower rain') {
                setWeatherImage(heavy_rain);
            }
            if (data.weather[0].description === 'snow') {
                setWeatherImage(snow);
            }
            if (data.weather[0].description === 'thunderstorm') {
                setWeatherImage(lightning);
            }
            if (data.weather[0].description === 'mist') {
                setWeatherImage(mist);
            }
        } catch (error) {
            console.error('Error fetching weather data:', error);
        }
    };

    let url = apiUrl;
    let levelPercentage = 0;
    let waterWarning = '';

    const getWaterLevel = async () => {
        try {
            const response = await fetch(url + 'app/getRecentWaterLevel');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setWaterLevel(data.waterLevel);

        } catch (error) {
            console.error('Error fetching water level data:', error);
        }
    };

    const getBusinessInfo = async () => {
        try {
            const response = await fetch(url + 'app/businessSelection/' + user.businessId);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) {
                setBusinessName(data.business.businessName);
                if (data.business.membershipStatus || data.business.membershipStatus !== '0000-00-00 00:00:00') {
                    setMembershipStatus(data.business.membershipStatus)
                }

            }
        } catch (error) {
            console.error('Error fetching business data:', error);
        }
    };

    const getAllBusinesses = async () => {
        try {
            const response = await fetch(url + 'app/currentcard/');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) {
                setMembersNumber(data.business.length);
            } else {
                setMembersNumber(0);
            }
        } catch (error) {
            console.error('Error fetching businesses data:', error);
        }
    };

    const getAwaitingEvents = async () => {
        try {
            const response = await fetch(url + 'app/allAwaitingEvents/');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) {
                setAwaitingEvents(data.events.length);
            } else {
                setAwaitingEvents(0);
            }

        } catch (error) {
            console.error('Error fetching events data:', error);
        }
    };

    const getPendingJobs = async () => {
        try {
            const response = await fetch(url + 'app/allPendingJobs/');

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.isValid) setPendingJobs(data.jobs.length);
        } catch (error) {
            console.error('Error fetching jobs data:', error);
        }
    };

    useEffect(() => {
        getWeather();
        getWaterLevel();
        getBusinessInfo();
        getAllBusinesses();
        getAwaitingEvents();
        getPendingJobs();
    }, []);

    if (!isNaN(parseFloat(waterLevel))) {

        levelPercentage = parseFloat(waterLevel) / 2.5;

        if (levelPercentage < 0.44) {
            waterWarning = 'Water level without danger';
        } else if (0.44 < levelPercentage < 0.64) {
            waterWarning = 'Be cautious of the water level';
        } else if (levelPercentage > 0.64) {
            waterWarning = 'Risk of flood';
        }
    } else {
        setWaterLevel(0);
    }

    const chartStyle = {
        width: 300,
    }

    let membershipDate = membershipStatus.split(' ');
    membershipDate = membershipDate[0].split('-')
    let membershipMonth = monthNames[Number(membershipDate[1] - 1)];
    let membershipDay = membershipDate[2];
    let membershipYear = membershipDate[0];

    return (
        <div className={styles.infoContainer}>
            <div className={'bg-light-green ' + styles.card}>
                <HeadingTwo title={'Member Information'} className={''}/>
                <div key={user.id} className={styles.infoWrapper}>
                    <div>
                        <div className={styles.cardItem}>
                            <GoPerson size={20}/>
                            <p>{user.fullName}</p>
                        </div>
                        <div className={styles.cardItem}>
                            <HiOutlineMail size={20}/>
                            <p>{user.email}</p>
                        </div>
                        <div className={styles.cardItem}>
                            <IoBusinessOutline size={20}/>
                            <p>{businessName}</p>
                        </div>
                    </div>
                    <div>
                        <Link to={`https://localis.tech/en-GB/ie/Ireland/${businessName}%20Midleton`}
                              target={"_blank"} className={styles.link}>Check My Local SEO</Link>
                    </div>
                </div>
                {
                    user.role === 1 ?
                        <div className={'dash-info'}>
                            <HeadingTwo title={'Members\' Information'} className={'mt-10'}/>
                            <p>There are {membersNumber} members on {currentMonth} {currentDay}, {currentYear}.</p>
                            <Link to={'/events-workshops/event-management'}>{
                                awaitingEvents > 1 ?
                                    <p>There are {awaitingEvents} events waiting to be published.</p>
                                    :
                                    <p>There is {awaitingEvents} event waiting to be published.</p>
                            }
                            </Link>
                            <Link to={'/job-listing/manage-jobs'}> {
                                pendingJobs > 1 ?
                                    <p>There are {pendingJobs} job listings waiting to be published.</p>
                                    :
                                    <p>There is {pendingJobs} job listing waiting to be published.</p>
                            }</Link>
                        </div>
                        : membershipStatus === '0000-00-00 00:00:00' ?
                            <>
                                <HeadingTwo title={'Account Status'} className={'mt-10'}/>
                                <p>Next Membership Payment due: Never</p>
                            </>
                            :
                            <>
                                <HeadingTwo title={'Account Status'} className={'mt-10'}/>
                                <p>Next Membership Payment due:</p>
                                <p>{membershipMonth} {membershipDay}, {membershipYear}</p>
                            </>
                }
                <LocalisBenchmark businessName={businessName}/>
            </div>
            <div className={'bg-light-green ' + styles.card}>
                <HeadingTwo title={'Weather'} className={''}/>
                <p>{currentMonth} {currentDay}, {currentYear}</p>
                <img src={weatherImage} alt={'weather'} width={50}/>
                <p>{description}<br/>
                    {weatherTemp} / Feels like {weatherTempFeels}<br/>
                </p>
                <HeadingTwo title={'Water Level'} className={'mt-10'}/>
                <GaugeChart id="gauge-chart1" style={chartStyle} className={'m-auto'}
                            arcsLength={[0.44, 0.2, 0.36]}
                            percent={levelPercentage}
                            textColor={'#000'}
                            formatTextValue={value => waterLevel + 'm'}
                            needleColor={'#063a2ea3'}
                />
                <p className={'align-center mb-0'}>{waterWarning}</p>
            </div>
        </div>
    )
        ;
}