import React from "react";
import logo from '../../assets/img/towncrier-logo.png'
import styles from './login.module.css'
import LoginForm from "../../components/towncrier/login-form";
import {Link} from "react-router-dom";

export default function Login() {

    return (
        <main className={'login'}>
            <div className={'section-col'}>
                <div className={'w-50 ' + styles.colLeft}>
                    <img src={logo} alt={'TownCrier logo'}/>
                    <p>Your go-to platform for staying connected with your community. Join us to discover local events,
                        news and connect with your community</p>
                </div>
                <div className={'w-50 section'}>
                    <h1 className={styles.h1}>Log In</h1>
                    <LoginForm />
                    <div className={'mt-10 news'}>
                        <Link  to={'/privacy-policy'}>Privacy Policy</Link>
                    </div>
                </div>

            </div>
        </main>
    );
}