import React, {useState} from "react";
import Heading from "../../components/towncrier/ui/heading";
import AllEvents from "../../components/towncrier/all-events";
import Button from "../../components/towncrier/ui/button";

export default function EventsWorkshops() {
    const [user] = useState(JSON.parse(localStorage.getItem("user")));

    return (
        <main className={'events-workshops towncrier-content'}>
            <div className='section'>
                <Heading title={'Upcoming Events & Workshops'}/>
                <div className={'mb-10 members-button'}>
                    <span className={'mr-5'}><Button href={'create-event'} text={'Create an Event'}/></span>
                    {
                        user.role === 1 ?
                            <Button href={'event-management'} text={'Manage Events'}/>
                            : ''
                    }
                </div>
                <AllEvents user={user}/>
            </div>
        </main>
    );
}