import React from 'react';
import styles from './resource-card.module.css'
import {Link} from "react-router-dom";

export default function ResourceCard({id, type, title, content}) {
    let slug = title.toLowerCase().replaceAll(' ', '-')
    return (
        <div key={id}>
        <p className={styles.type}><span>{type}</span></p>
        <p className={styles.title}>{title}</p>
        <p>
            {content.length > 250 ?
                `${content.substring(0, 85)}...` : content
            }
        </p>
        <span><Link to={slug} state={{
            id: id,
            type: type,
            title: title,
            slug: slug,
            content: content
        }}>View More</Link></span>
    </div>
    )
}