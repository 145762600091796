import React, {useEffect, useState} from "react";
import {Navigate, useLocation} from "react-router-dom";

import { useAuth } from "./Auth";

const PrivateRoute = ({ children }) => {
    const { authTokens } = useAuth();
    let location = useLocation();
    const [referer, setReferer] = useState('')

    useEffect(() => {
        if (location.pathname === '/privacy-policy') {
            setReferer('/privacy-policy')
        } else {
            setReferer('/login')
        }
    }, [location.pathname]);

    return authTokens ? <>{children}</> : <Navigate to={referer} />;
};

export default PrivateRoute;
