import React, {useEffect, useState} from 'react';
import styles from "./ui/button.module.css";
import style from './login-form.module.css'
import {useNavigate} from "react-router-dom";
import api from "../../api";
import Button from "./ui/button";

export default function CreateOfferForm() {

    const [discount, setDiscount] = useState('');
    const [code, setCode] = useState('');
    const [validity, setValidity] = useState('');
    const [user] = useState(JSON.parse(localStorage.getItem("user")));
    const [error, setError] = useState('');
    const [businessName, setBusinessName] = useState('');

    const url = api;
    const navigate = useNavigate();

    const getBusinessInfo = async () => {
        try {

            const response = await fetch(url + 'app/businessSelection/' + user.businessId);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setBusinessName(data.business.businessName);
        } catch (error) {
            console.error('Error fetching business data:', error);
        }
    };

    function handleSubmit(e) {
        // Prevent the browser from reloading the page
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);

        console.log(user)

        // Or you can work with it as a plain object:
        const formJson = Object.fromEntries(formData.entries());
        formJson.businessName = businessName;
        formJson.locationID = user.location;
        formJson.businessID = user.businessId;

        console.log(formJson)

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formJson)
        };

        fetch(url + 'app/createOffer', options)
            .then(response => response.json())
            .then(data => {
                if (data.isValid) {
                    alert("Offer created!");
                    navigate('/settings');
                } else {
                    setError('We couldn’t create your offer. Please check the information entered and try again.')
                }
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        getBusinessInfo();
    }, []);

    return (
        <form method="post" onSubmit={handleSubmit} className={style.mailingForm}>
            <label>
                Discount <input className={style.mailingInput + ' search-input '} placeholder='10%'
                                required={true} type='text' name="discount" value={discount}
                                onChange={e => setDiscount(e.target.value)}/>
            </label>
            <label>
                Code <input className={style.mailingInput + ' search-input '} placeholder='MEMBER10'
                            required={true} onChange={e => setCode(e.target.value)}
                            type='text' name="code" value={code}/>
            </label>
            <label>
                Validity <input className={style.mailingInput + ' search-input '} placeholder='Validity'
                                required={true} type='datetime-local' name="validity" value={validity}
                                onChange={e => setValidity(e.target.value)}/>
            </label>
            <div>
                <button className={styles.button + ' mr-5'} type="submit">Publish</button>
                <Button text={'Go Back'} href={'..'} />
            </div>
            {error ? <p className={style.error}>{error}</p> : null}
        </form>
    );
}